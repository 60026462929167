<template>
    <div>

    </div>
</template>


<script>
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            // Code...
        }
    },
    computed: {
        ...mapGetters('AuthIndex', ['rules'])
    },
    created() {
        this.$eventHub.$on('select-at-least-one-course', this.selectAtLeastOneCourse)
        this.$eventHub.$on('select-at-least-one', this.selectAtLeastOne)
        this.$eventHub.$on('sms-sent', this.smsSent)
        this.$eventHub.$on('add-at-least-one-member', this.addAtLeastOneMember)
        this.$eventHub.$on('nothing-to-move', this.nothingToMove)
        this.$eventHub.$on('sms-not-sent', this.smsNotSent)
        this.$eventHub.$on('create-success', this.itemCreated)
        this.$eventHub.$on('update-success', this.itemUpdated)
        this.$eventHub.$on('delete-success', this.itemDeleted)
        this.$eventHub.$on('rules-update', this.rulesUpdate)
        this.$eventHub.$on('status-update', this.cancelUpdate)
        this.$eventHub.$on('delete-error', this.itemNotDeleted)
        this.$eventHub.$on('something-went-wrong', this.somethingWentWrong)
        this.$eventHub.$on('mark-as-completed', this.markAsClosed)
        this.$eventHub.$on('mark-as-comp', this.markAsCompleted)
        this.$eventHub.$on('report-card', this.reportCard)
        this.$eventHub.$on('inquiry-exists', this.inquiryExists)
        this.$eventHub.$on('send-mail', this.sendMail)
        this.$eventHub.$on('revert-course', this.revertCourse)
        this.$eventHub.$on('action-error', this.actionError)
        this.$eventHub.$on('excel-update', this.excelUpdate)
        this.$eventHub.$on('put-on-hold', this.putOnHold)
        this.$eventHub.$on('common', this.common)
        this.$eventHub.$on('enroll-status-update', this.enrollStatusUpdate)
    },
    methods: {
        addAtLeastOneMember() {
            this.$awn.warning('Add at least one member')
        },
        itemCreated() {
            //console.log("succ");
            this.$awn.success('Your record has been successfully saved.')
        },
        nothingToMove() {
            this.$awn.warning('Nothing to move')
        },
        smsSent() {
            this.$awn.success('SMS has been successfully sent.')
        },
        selectAtLeastOne() {
            this.$awn.warning('Select at least one student.')
        },      
        selectAtLeastOneCourse() {
            this.$awn.warning('Select at least one course.')
        },                
        itemUpdated() {
            this.$awn.success('Your record has been successfully updated.')
        },
        enrollStatusUpdate() {
            this.$awn.success('Course status has been changed successfully.')
        },
        itemDeleted() {
            this.$awn.success('Your record has been successfully deleted.')
        },
        rulesUpdate() {
            this.$ability.update([{ subject: 'all', actions: this.rules }])
        },
        cancelUpdate() {
            this.$awn.success('Your record status has been successfully updated.')
        },
        // Delete error message
        itemNotDeleted() {
            this.$awn.alert('You have already used this record in other places.')
        },
        somethingWentWrong() {
            this.$awn.alert('Student is not enrolled in selected course.')
        },   
        smsNotSent() {
            this.$awn.alert('Something went Wrong.')
        },                
        markAsClosed() {
            this.$awn.info('Your record has been successfully marked as closed.')
        },
        revertCourse() {
            this.$awn.info('Your record has been successfully reverted.')
        },
        markAsCompleted() {
            this.$awn.info('Your course has been successfully marked as completed.')
        },
        reportCard() {
            this.$awn.info('Your report card has been successfully send.')
        },
        inquiryExists() {
            this.$awn.info('Inquiry already exists.')
        },
        sendMail() {
            this.$awn.success('Mail successfully sent to parent.')
        },
        actionError() {
            this.$awn.alert('Sorry something went wrong! Action couldn\'t be completed.')
        },
        excelUpdate(msg) {
            msg = msg ? msg: 'Excel Sheet has been imported successfully.';
            this.$awn.success(msg)
        },   
        putOnHold(type = 1,msg){
          if(type == 1){
            msg = msg ? msg : 'The batch has been put on hold successfully'
            this.$awn.success(msg)
          }else if(type == 2){
            msg = msg ? msg : 'something might wrong'
            this.$awn.alert(msg)
          }else{
            msg = msg ? msg : 'something might wrong'
            this.$awn.info(msg)
          }
        },
        common(type = 1,msg){
          if(type == 1){
            msg = msg ? msg : 'The operation has been successfully performed'
            this.$awn.success(msg)
          }else if(type == 2){
            msg = msg ? msg : 'something might wrong'
            this.$awn.alert(msg)
          }else if(type == 3){
            msg = msg ? msg : 'something might wrong'
            this.$awn.warning(msg)
          }else{
            msg = msg ? msg : 'something might wrong'
            this.$awn.info(msg)
          }
        },
    }
}
</script>


<style scoped>

</style>
